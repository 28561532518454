<template>
 <div style='width: 100%; height: 100%; position: relative;'>
 
    <div class='transition' :style='getControlStyle()'>
			<div style='float: top; width: 100%; vertical-align: middle;'>
		        
		       <div style='vertical-align: middle; text-align: center; font-size: 11pt; font-weight: bold;width: 100%;'>AVAILS DASHBOARD 1.0</div>
		        <div class='noBreak' style='width: 100% !important; font-size: 9pt; font-weight: bold;'> 
		        	<center v-if="station.logo">
	    	        <img style='width: 25%; height: auto;' :src="'data:image/png;base64,'+station.logo"/>
	    	        </center>
	    	        <span v-else>
	    	        
	    	     	{{station.name}}
	    	     	</span>
	    	     </div> 

		        
		        <span class='SVcontrolLabel'>Channel/Station:</span><br/>
			
			    <GSelectSimple :options="stations" style='width:100%' v-model="stationId" @changed='stationChanged'/>
			    
			    <br/>
			    <span class='SVcontrolLabel'>From date:</span><br/>
			    <InputDatePick :auto="false" @change="dateChanged" v-model="activeDate"/>     
				<InputWeekdays fontsize='7pt' width="100%" v-model="weekdayValue"/>
		        
		        <div class='SVcontrolLabel'>Days to display:</div><br/>
		        <vue-slider @change='val => noOfDaysChanged(val)' :marks=false min=1 max=32 v-model="noOfDays"></vue-slider>	
		    
			    <span class='SVcontrolLabel'>2nd Timezone:</span><br/>
			    <GSelect placeholder='choose second timezone' :options="timezones" style='width:100%' v-model="selectedTimezone" @input='tzChanged'>
			          
			    </GSelect>
			    
			    <div class="BMSection">
		              <span class='BMSectionDetail dontWrap90 smallText'><b>FILTER:</b></span>
		        </div>

				<span class='dontWrap smallText bold'>{{tr("Inventory")}}:</span>
				<GSelect :options="allInventories" style='font-size: 9pt;' v-model="selectedInventories" :multiple="true" @input="processChangeImmed()">  
		        
			    </GSelect>
		        
				<span class='dontWrap smallText bold'>Program Categories:</span>
			    <GSelect :options="allProgCats" style='font-size: 9pt;' v-model="selectedProgCats" :multiple="true" @input="processChange()">  
		        
		        </GSelect>
		        
			    <span class='dontWrap smallText bold'>Grid/Programs:</span>
			    <GSelect :options="allPrograms" style='font-size: 9pt;' v-model="selectedPrograms" :multiple="true" @input="processChange()">  
		        </GSelect>
		        
		        <span class='dontWrap smallText bold'>Clients:</span>
			    <GSelect :options="allClients" style='font-size: 9pt;' v-model="selectedClients" :multiple="true" @input="processChange()">  
		        </GSelect>
		        <span class='dontWrap smallText bold'>Business Type:</span>
			    <GSelect :options="allBusinessTypes" style='font-size: 9pt;' v-model="selectedBusinessTypes" :multiple="true" @input="processChange()">  
		        </GSelect>
 				<span class='dontWrap smallText bold'>{{tr("ProductFamily")}}:</span>
			    <GSelect :options="allFamilies" style='font-size: 9pt;' v-model="selectedFamilies" :multiple="true" @input="processChange()">  
		        </GSelect>

				<button :disabled='allPrograms.length==0 || allClients.length==0' class='button myButton' @click="resetFilter()">Clear</button>
				
		        <br/>
		        
			    <span class='SVcontrolLabel'>Only Placed: </span>
		    	&nbsp;
		    	<app-switch v-model="onlyPlaced" :checked="onlyPlaced"/>
			    <!-- 
			    <br/>
			    <span class='SVcontrolLabel'>Show Controls: </span>
		    	&nbsp;
		    	<app-switch v-model="controlsRight" :checked="controlsRight"/>
		         -->
                
				<div style='margin-top: 8pt;' class="BMSection">
		              <span class='BMSectionDetail dontWrap90 smallText'><b>ACTIONS</b></span>
		        </div>
			    <button :disabled='false' class='button myButton' @click="reload()">Reload</button>
			   


		    </div>

 	</div>
	<div class='transition'  style='float: left; width: 82%; height: 26pt; overflow-y: scroll; '> 
	    <div style='width: 100%; float:top; border: 1pt solid grey;'>
	    	<div class='BMdayH' style='width: 30pt !important; font-size: 8pt; font-weight: bold;'>Time</div>
	    	<div v-if="selectedTimezone && selectedTimezone.id" class='noBreak BMday' style='width: 30pt !important; font-size: 8pt; font-weight: bold;'>{{printTZ(selectedTimezone.label)}}</div>
	    	<div class='BMdayH' style='width: 100pt !important; font-size: 8pt; font-weight: bold;'>Program</div>
	    	<div class='BMdayH' style='width: 40pt !important; font-size: 8pt; font-weight: bold;border-left: 1px solid #ccc;border-right: 1px solid #ccc;'>Totals</div>
			
			<span v-for="(day,idx) in validDates(dates)" :key="'D'+idx" class='BMday' 
			    @click='openDetailP(stationId, isoDates[day])'
			    style="text-align: middle; cursor: pointer; width: 35pt !important; margin: 2pt;">
			    <div class='BMdayH noBreak' :style="'font-size: 8pt; width: 32pt !important; font-weight: bold; background-color: '+dayStats[isoDates[day]].colour+';'">
			     {{weekdayLabel[day]}}{{ day}}<div class='noBreak simple'>{{dayStats[isoDates[day]].shortname}}</div>
			    </div>
			</span>
		</div>
	</div>	
	<div class='transition noBreak' style='float: left; width: 82%; height: calc(82vH); overflow-y: scroll; overflow-x: scroll;'> 
		<div style='float:left; position: relative;'>
			<div v-for="(iGrid,idx) in filter(data)" :key="'M'+idx" class='noBreak'  :title="'PG'+iGrid.rateCard.pricegroup"
				@click="$refs.editor.open('GridDetail', iGrid.detail.id)"
			     style="'cursor: pointer; font-size: 9pt; height: 23px; width: 100%; border-bottom: 1px solid #ddd; border-left: 1px solid #ccc;">
				<div  :style="'display: inline-flex; width: 30pt; height: 100%;'">
				<!-- 
				background-color:'+iGrid.rateCard.colour+';'">
				-->
				{{printTimeHHMM(iGrid.starttime)}}
				</div>
				<div v-if="selectedTimezone && selectedTimezone.id" :style="'display: inline-flex; color: #888; width: 30pt; border-left: 1px solid #ccc; height: 100%; font-size: 9pt; '">
				{{printTimeHHMM(iGrid.starttime+iGrid.diff)}}
				</div>
				<div :style="'display: inline-flex; width: 100pt; height: 100%; font-size: 9pt; border-left: 1px solid #ccc;'">
				{{iGrid.detail.name}}
				</div>
			</div>
		</div>
		<div v-for="(iGrid,gidx) in filter(data)" :key="'M'+gidx" style='float: top; font-size: 9pt; width: 100%; ' >
			<div style='width: calc(100vH-140pt); float:top; position: relative; height: 23px;'>
			    
			    <div class='BMday' style='width: 40pt !important; font-size: 9pt; font-weight: bold; border-bottom: 1px solid #ddd; border-left: 1px solid #ddd;border-right: 1px solid #ddd;'>
			    	<progress-bar 
					              :options="getPBOptions(getUsage(iGrid.detail.id, 0), capacity[ iGrid.detail.id])"
					              :value="Math.round(100*getUsage(iGrid.detail.id, 0) / capacity[ iGrid.detail.id])"
					              />
			    </div>
				<div v-for="(day,idx) in validDates(dates)" :key="'D'+gidx+'.'+idx" class='BMday' 
				    @click='viewGridAds(iGrid,isoDates[day])' :style="getCellStyle(iGrid, day)">
					<progress-bar v-if="hasAdBreak(iGrid, day)"
					              :options="getPBOptions(getUsage(iGrid.detail.id, isoDates[day]), iGrid.adDurationInSec)"
					              :value="Math.round(100*getUsage(iGrid.detail.id, isoDates[day])/iGrid.adDurationInSec)"
					              />
				</div>
				
			</div>
		</div>
		
	</div>
	<GConfirm ref='confirm'/>
	<GFWEOpenEditor ref='editor' @update='doReload'></GFWEOpenEditor>
	<GridBreakViewer name='gridViewer' ref='gridViewer'/>
	<ProgressBarMAIN v-if="showProgressBar" :generalInfo=pbTitle :action=pbAction @action=action></ProgressBarMAIN>
 </div>
</template>	
<script>
import {HTTP, HTTPMP, asrAPI, opsAPI, bngAPI, fwAPI, invAPI, setReload, showError, myLocale} from '@/variables.js';
import {store, restore} from '@/runDown.js';
import { getAppStyle, initAppMode } from '@/AppStyle.js';
import {printTimeOpt, printTimeHHMM, printTime } from '@/basicTimeFN.js';
import { setGoBack } from '@/breadCrumb.js';
import { tr } from '@/translate.js';
import InputTime from '@/components/inputElements/InputTime2';
import ProgressBarMAIN from '@/components/ProgressBar';
import Switch from '@/components/Switch';
import ContextMenu from '@/components/ContextMenu';
import ContextMenuItem from '@/components/ContextMenuItem';
import GSelectSimple from '@/components/GSelectSimple';
import InputTimezone from '@/components/inputElements/InputTimezone';
import GSelect from '@/components/misc/GSelect';
import InputWeekdays from '@/components/inputElements/InputWeekdays';
import GridBreakViewer from '@/components/GridBreakViewer';
import GConfirm from '@/components/GConfirm';
import InputDatePick from '@/components/inputElements/InputDatePick3';
import JQuery from "jquery";
import 'w3-css/w3.css';
let $ = JQuery;
var timers = [];
export default {
  name: 'GFW_AVAILS',
   
  components : {
   GSelect, 'app-switch': Switch, InputDatePick, GConfirm, GSelectSimple, GridBreakViewer, InputWeekdays, ProgressBarMAIN
  },
  data () {
    return {
      tr,
      stationId: 0,
      station: {},
      stations: [],
      bgColor: '#fcc',     
      lookForSpot: {},
      lookForLine: {},
      updateLine: {},
      tz: {},
      weekdayValue: 127,
	  // progBar
        pbAction: "",
        pbTitle: "",
        showProgressBar: false,
      noOfDays: 0,
      fontSize: 10,
      dates: [],
      weekdayLabel: [],
      weekdays: [],
      weekdayBITS: [],
      marked: [],
      gridData: [],
      timeSlots: [],
      usage: [],
      capacity: [],
      
      isoDates: [],
	  dayStats: {},
      showDangling: [],
      loadingActive: false,
      selectedTimezone: {},
      selectedStation: {},
      tz2: {},
      diff: 0,
      timezones: [],
      timezoneId: 0,
      activeDate: {},
      dataDay: {},
      data: [],
      spots: [],
      dayStatus: {},
      controlsRight: false,
      updatedSpots: [],
      clbId: "",
      counter: 0,
	  printTimeHHMM,
      //
      allPrograms: [],
      allProgCats: [],
      selectedPrograms: [],
      selectedProgCats: [],
      allInventories: [],
      inventoryMap: {},
      //
      onlyPlaced: true,
      allClients: [],
      allBusinessTypes: [],
      selectedClients: [],
      selectedBusinessTypes: [],
      allFamilies: [],
      selectedFamilies: [],
      selectedInventories: [],
     
      PBOptions: {
		  text: {
			color: '#000',
			shadowEnable: false,
		    fontSize: 10,
		    fontFamily: 'Helvetica',
		    dynamicPosition: false,
		    hideText: false
		  },
		  progress: {
		    color: '#6d6',
		    backgroundColor: '#dedeff'
		  },
		  layout: {
		    height: 16,
		    width: 40,
		    verticalTextAlign: 71,
		    horizontalTextAlign: 22,
		    zeroOffset: 0,
		    strokeWidth: 0,
		    progressPadding: 0,
		    type: 'line'
		  }
		 },
		PBOptionsRED: {
		  text: {
			color: '#000',
			shadowEnable: false,
		    fontSize: 10,
		    fontFamily: 'Helvetica',
		    dynamicPosition: false,
		    hideText: false
		  },
		  progress: {
		    color: '#f88',
		    backgroundColor: '#dedeff'
		  },
		  layout: {
		    height: 16,
		    width: 40,
		    verticalTextAlign: 71,
		    horizontalTextAlign: 22,
		    zeroOffset: 0,
		    strokeWidth: 0,
		    progressPadding: 0,
		    type: 'line'
		  }
		 },
		 PBOptionsZERO: {
		  text: {
			color: '#aaa',
			shadowEnable: false,
		    fontSize: 10,
		    fontFamily: 'Helvetica',
		    dynamicPosition: false,
		    hideText: false
		  },
		  progress: {
		    color: '#eee',
		    backgroundColor: this.bgColor
		  },
		  layout: {
		    height: 16,
		    width: 40,
		    verticalTextAlign: 71,
		    horizontalTextAlign: 22,
		    zeroOffset: 0,
		    strokeWidth: 0,
		    progressPadding: 0,
		    type: 'line'
		  }
		 }
		
      }
  },
  methods: {
    myLocale() { return myLocale();},
    getPBOptions(usage, capa)
    {
    	if ( usage == 0 )
    	{
    		return this.PBOptionsZERO;
    	}
    	if ( usage > capa )
    	{
    		return this.PBOptionsRED;
    	}
    	else
    	{
    		return this.PBOptions;
    	}

    },
    action( what, myId, api)
    {
     		this[what](myId, api)
    },
    printTZ( tz)
    {
    	if ( tz.length < 5 )
    	{
    		return tz;
    	}
    	let arr = tz.split('/');
    	if ( arr.length > 1 )
    	{
    		return arr[0].substr(0,1)+"/"+arr[1].substr(0,2);
    	}
    	return tz.substr(0,4);
    },
    openDetailP(stationId, isoDay)
    {
    	setGoBack( this.$router.currentRoute.path, this.$router.currentRoute.name);
    	if (this.dayStats[isoDay].aired == 0)
    	{
    		this.$router.replace('detailPlanning?stationId='+stationId+'&day='+ isoDay);
    	}
    	if (this.dayStats[isoDay].aired == 1)
    	{
    		this.$router.replace('AsRun?stationId='+stationId+'&day='+ isoDay);
    	}
    },
    filter(data)
    {
    	if ( this.selectedPrograms && this.selectedPrograms.length )
    	{
    		return data.filter(p=>this.selectedPrograms.includes(p.content.name))
    	}
    	if ( this.selectedProgCats && this.selectedProgCats.length )
    	{
    		return data.filter(p=>this.selectedProgCats.includes(p.category.name))
    	}
    	return data;
    },
    viewGridAds( iGrid, date)
    {
    	let that = this;
    	
    	let day = new Date(date).toISOString().split('T')[0];
    	HTTP.post( bngAPI+"/getGridSpots/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+this.stationId+"/"+day+"/"+iGrid.detail.id)
            .then( response => 
            { 
                that.$refs.gridViewer.open('Grid View', iGrid, response.data);
                
            }).catch(e => {
                   that.$toast.error(e.response.data, 'Error', { position: "topRight" });
            });
    },
    getDays()
    {
		if ( !this.stationId )
    	{
    		return;
    	} 
	    this.pbAction = "getDaysInternal";
	    this.pbTitle = "loading grid";
	   	this.showProgressBar = true;
	},	
    getDaysInternal( myWsId, api)  
    {
    	let that = this;
    	
    	let dateFrom = new Date(this.activeDate).toISOString().split('T')[0];
    	let day = new Date( this.activeDate);
    	day.setDate( day.getDate() + this.noOfDays - 1);
        let dateTo = new Date(day).toISOString().split('T')[0];
        this.getDates();
        let tz = sessionStorage.timezoneId;
        if ( this.selectedTimezone && this.selectedTimezone.id )
    	{
    		tz = this.selectedTimezone.id;
    	}	
    	let selectedInventories=new Array();
    	for ( let x in that.selectedInventories)
    	{
    		selectedInventories.push(that.inventoryMap[that.selectedInventories[x]].id)
    	}
    	//alert( JSON.stringify(that.selectedInventories));
        //alert( invAPI+"/findInventoryChannel/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+this.stationId+"/"+dateFrom+"/"+dateTo+"/"+tz+"/"+JSON.stringify(selectedInventories));
    	HTTP.post( invAPI+"/findInventoryChannel/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+this.stationId+"/"+dateFrom+"/"+dateTo+"/"+tz+"/"+1+"/"+myWsId, selectedInventories)
        		 .then( response => 
                 {  
                    that.data = response.data.grid;
                    that.showProgressBar = false;
					that.gridData = new Array();
             		that.allPrograms = new Array();
             		that.allProgCats = new Array();
                    that.timeSlots = new Array();
                    for ( let iFilter in response.data.filter )
                    {
                    	let filter = response.data.filter[iFilter];
                    	if ( !that.allInventories.includes(filter.name))
                        {
                        	that.allInventories.push( filter.name);
                        	that.inventoryMap[filter.name] = filter;
                        }
                        
                    }
                    that.allInventories.sort();
                    for ( var grd in that.data )
                    {
                        let elem = that.data[grd];
                        
                        let starttime = elem.starttime;
                        let mID = elem.media.id;
                        if ( !that.allPrograms.includes(elem.content.name))
                        {
                        	that.allPrograms.push( elem.content.name);
                        }
                        if ( !that.allProgCats.includes(elem.category.name))
                        {
                        	that.allProgCats.push( elem.category.name);
                        }
                        if ( ! that.gridData[mID] )
                        {
                        	that.gridData[mID] = new Array();
                        }
                        if ( ! that.timeSlots[mID] )
                        {
                        	that.timeSlots[mID] = new Array();
                        }
                        if ( ! that.gridData[mID][starttime] )
                        {
                        	that.gridData[mID][starttime] = new Array();
                        }
                    	that.gridData[mID][starttime].push(elem);
                    	
                    	let slots = that.timeSlots[mID].length;
                    	
                    	if ( slots == 0 || that.timeSlots[mID][slots-1] !== starttime)
                    	{
                    		that.timeSlots[mID].push(starttime);
                    	}
                    	this.capacity[elem.detail.id] = 0;
                    	for (let d in this.dates)
                    	{
                    		let day = this.dates[d];
                    		if ( this.hasAdBreak(elem, day) )
                    		{
                    			this.capacity[elem.detail.id] += elem.adDurationInSec;
                    		} 
                    	}

                    }
                    that.allPrograms.sort();
                 	that.getDayStats();
                 	that.allProgCats.sort();
                 	that.getSpots();
                 }).catch(e => {
					    that.showProgressBar = false;
						showError( that.$toast, "loading inventory", e);
                    });
    },
    getDayStats()
    {
    	let that = this;
    	let dateFrom = new Date(this.activeDate).toISOString().split('T')[0];
    	let day = new Date( this.activeDate);
    	day.setDate( day.getDate() + this.noOfDays - 1);
        let dateTo = new Date(day).toISOString().split('T')[0];
        //alert( invAPI+"/getDayTXStats/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+this.stationId+"/"+dateFrom+"/"+dateTo);
    	HTTP.get( invAPI+"/getDayTXStats/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+this.stationId+"/"+dateFrom+"/"+dateTo)
        		 .then( response => 
                 {  
                     this.dayStats = response.data;
                     //alert( JSON.stringify(this.dayStats))
                 }).catch(e => {
						showError( that.$toast, "loading inventory", e);
                    });
    },
    getSpots()
    {
        let that = this;
        let dateFrom = new Date(this.activeDate).toISOString().split('T')[0];
    	let day = new Date( this.activeDate);
    	day.setDate( day.getDate() + this.noOfDays - 1);
        let dateTo = new Date(day).toISOString().split('T')[0];
       	
    	
	    HTTP.post( bngAPI+"/findAllSpotsPlusClients/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+dateFrom+"/"+dateTo+"/"+that.onlyPlaced, [that.stationId])
        		 .then( response => 
                 {  
                    that.spots = response.data;
                    that.allClients = [];
                    that.allBusinessTypes = [];
                    that.allFamilies = [];
                    that.doFilterSpots( that.spots );
                        
                 }).catch(e => {
                        console.log("loading data for client", JSON.stringify(e));
                        console.log( JSON.stringify(e));

                    });
    },
    doFilterSpots( spots)
    {
    	
        let that = this;
        that.usage = [];
        let countClients = [];
        let countBusinessTypes = [];
        let countFamilies = [];
        for ( let c in spots.clients )
        {
        	if ( !that.allClients.includes(spots.clients[c]))
        	{
        		that.allClients.push( spots.clients[c] )
        	}
        	if ( that.selectedClients == null || that.selectedClients.length == 0 || that.selectedClients.includes(spots.clients[c]) )
        	{
        		countClients.push(parseInt(c));
        	}
        	that.allClients.sort();
        }
       
        for ( let c in spots.businessTypes )
        {
        	
        	if ( !that.allBusinessTypes.includes(spots.businessTypes[c]))
        	{
        		that.allBusinessTypes.push( spots.businessTypes[c] )
        	}
        	if ( that.selectedBusinessTypes == null || that.selectedBusinessTypes.length == 0 || that.selectedBusinessTypes.includes(spots.businessTypes[c]) )
        	{
        		countBusinessTypes.push(parseInt(c));
        	}
        	that.allBusinessTypes.sort();
        }
        for ( let c in spots.families )
        {
        	if ( !that.allFamilies.includes(spots.families[c]))
        	{
        		that.allFamilies.push( that.spots.families[c] )
        	}
        	if ( that.selectedFamilies == null || that.selectedFamilies.length == 0 || that.selectedFamilies.includes(spots.families[c]) )
        	{
        		countFamilies.push(parseInt(c));
        	}
        	that.allFamilies.sort();
        }
       
        for ( let s in spots.spots )
        {
        	let spot = spots.spots[s];
        	if ( countClients.includes( spot.placementId ) && countBusinessTypes.includes( spot.placementId ) && countFamilies.includes( spot.productId ))
        	{
	         	//alert( JSON.stringify( spot));
	         	let iGrid = spot.gridId;
	         	let isoDay = spot.date;
	         	
	         	if ( !that.usage[iGrid] )
	         	{
	         		that.usage[iGrid] = [];
	         		that.usage[iGrid][0] = 0;
	         	}
	         	
	         	if ( !that.usage[iGrid][isoDay] )
	         	{
	         		that.usage[iGrid][isoDay] = 0;
	         	}
	         	
	         	that.usage[iGrid][isoDay] += spot.duration;
	         	that.usage[iGrid][0] += spot.duration;
	         }
        	//alert( JSON.stringify(that.usage[iGrid][isoDay] ));
        	//break;
        }


    },
    getUsage(id, date)
    {
    	if ( !this.usage[id] )
    	{
    		return 0;
    	}
    	if ( !this.usage[id][date])
    	{
    		return 0;
    	}
    	return this.usage[id][date]
    },
    activeWeekday(day)
    {
    	return this.weekdayValue & this.weekdays[day];
    },
    validDates(dates)
    {
    	let arr = [];
    	for ( let d in dates )
    	{
    		if ( this.activeWeekday( dates[d]) )
    		{	
    			arr.push( dates[d])
    		}
    	}
    	return arr;
    	
    },
    getDates()
    {
    	this.dates = new Array();
    	this.isoDates = new Array();
    	this.weekdays = new Array();
    	this.weekdayBITS = new Array();
    	var bit = 1;
    	for ( var i = 0; i < 7; i++)
    	{
    		this.weekdayBITS[i] = bit;
    		bit *= 2;
    	}
    	//alert( JSON.stringify( this.weekdayBITS));
    	let d = new Date(this.activeDate);
    	let untilDate = new Date(this.activeDate);
    	untilDate.setDate( untilDate.getDate() + this.noOfDays - 1);
    	untilDate.setHours( d.getHours());
    	untilDate.setMinutes( d.getMinutes());
    	untilDate.setSeconds( d.getSeconds());
    	//alert(d);
    	let locale = sessionStorage.locale.replace('_','-');
    	var options = { month: 'numeric', day: 'numeric' };
    	var weekdayLabelARR = [];
      	let wdArr = JSON.parse(sessionStorage.weekdays);
      	for ( var w=1; w < 8; w++ )
      	{
        	let x = wdArr[w];
         	if ( x.length > 2 )
         	{
             	x = x.substring(0,2);
         	}
         	weekdayLabelARR.push( x);    
      	}
      	var lastIso = "";
    	while ( d <= untilDate)
    	{
    	    let dateStr = d.toLocaleString( locale, options);
    		this.dates.push( dateStr );
    		let isoDate = d.toISOString().split('T')[0];
    		if ( isoDate === lastIso)
    		{
    			d.setHours(d.getHours()+4);
    			isoDate = d.toISOString().split('T')[0];
    		}
    		lastIso = isoDate;
    		this.isoDates[dateStr] = isoDate;
    	    this.weekdays[dateStr] = this.weekdayBITS[d.getDay()];
    	    this.weekdayLabel[dateStr] = weekdayLabelARR[d.getDay()];
    	    //alert( d.getDay()+"/"+this.weekdayBITS[d.getDay()]+"::"+ this.weekdays[dateStr]+"-"+dateStr);
    		d.setDate(d.getDate()+1);
    	}
    	//alert( d - untilDate);
    	return this.dates;
    },
    getCellStyle(grid, day)
    {
    	if ( this.hasAdBreak( grid, day) )
    	{
    		return "cursor: pointer; border-bottom: 1px solid #ddd; width: 35pt;"; // "background-color: #ddd;";
    	}
    	return "visibility: visible; border-bottom: 1px solid #ddd; width: 35pt !important;";
    },
    hasAdBreak(  grid, day)
    {
    	let wd = this.weekdays[day];
    	if ( !(grid.detail.weekdays & wd) )
    	{
    		return false;
    	}
    	//alert( this.isoDates[day]);
    	let d = new Date( this.isoDates[day]);
    	//alert( JSON.stringify(grid));
    	let pfrom = new Date( grid.fromDate);
    	//alert( pfrom+ " - " + (d < pfrom));
    	if ( d < pfrom)
    	{
    		return false;
    	}
    	let pTo = new Date( grid.untilDate);
    	if ( d > pTo)
    	{
    		return false;
    	}
    	return true;
    },
    getControlStyle() {
    	if ( this.controlsRight )
    	{
    		return "float: right; width: 18%; height: calc(100vh - 120px); padding: 2ex;"
    	}
    	return "float: left; width: 18%; height: calc(100vh - 120px); padding: 2ex;"
    },
    stationChanged(  stationId) 
    {
    	this.loadStation(  );
  		//this.getDays();
  		
    },
    dateChanged(newDate) {

    	this.activeDate = new Date( newDate);
    	this.getDays();
    },
    noOfDaysChanged(val) {

    	//this.getDays();
    },
    tzChanged(x) {
        this.timezoneId = x.id;
    	this.getDays();
    },
    reload()
    {
    	this.getDays();
    },
	processChange()
    {
     	this.doFilterSpots( this.spots);
    },
    processChangeImmed()
    {
     	this.getDays();
    },
    loadStation(  )
    {
    	
    	let that = this;
    	if ( !this.update )
    	{
    		this.update = new Array();
    	}
    	let activeDate = this.activeDate;
    	
		//this.startLoader()

		that.selectedPrograms = new Array();
    	that.selectedCopies = new Array();
    	that.selectedClients = new Array();
    	that.selectedProducts = new Array();
    	that.selectedStates = new Array();
    	if ( this.selectedTimezone && this.selectedTimezone.id )
        {
       	 	this.timezoneId = this.selectedTimezone.id;
        }
        let stationId = 0;
        if ( ! stationId )
        {
            if ( this.stationId )
            {
        		stationId = this.stationId;
	        }
	        else
	        {
	        	if ( this.selectedStation && this.selectedStation.id )
	        	{
	       	 		stationId = this.selectedStation.id;
	        	}
	        }
	    }
        if ( !this.selectedTimezone  )
        {
       		this.timezoneId = 0;
        }
        
        let time = new Date().getTime();
    	let stationLoad = new Promise(function (resolve, reject) {
            console.log(fwAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/Media/asOptions/true/0");
       		HTTP.get( fwAPI+"/getAsOptions/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/Media/true/0")
        		 .then( response => 
                 {
                 	let dur = (new Date().getTime()-time);
               		console.log("LOADED STATIONS in " + dur +"ms");  
                    var allStations = response.data.data;
                    that.stations = [];
                    for ( var channel in allStations )
                    {
                    	if ( allStations[channel].visible )
                    	{
                    		that.stations.push( allStations[channel]);
                    		if ( allStations[channel].id == stationId || ! stationId)
	                    	{
	                    		that.selectedStation = allStations[channel];
	                    		that.stationId = that.stations[channel].id;
	                    		stationId = that.stations[channel].id;
	                    	}
                    	}
                    	
                    }    
					
                    resolve (stationId);                    
                 }).catch(e => {
                        that.$toast.error("loading data for media/stations: " + e.response.data, 'Error', { position: "topRight" });
                        reject ();
                    });
	      });
	      stationLoad.then( selectedStationId => {
	        let timezoneLoad = new Promise(function (resolve, reject) {
		   	    console.log(invAPI+"/getMediaAndTimezone/"+sessionStorage.tenantId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/"+selectedStationId+"/"+that.timezoneId);
		        HTTP.get( invAPI+"/getMediaAndTimezone/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+selectedStationId+"/"+that.timezoneId)
	        		 .then( response => 
	                 {  
	                    let dur = (new Date().getTime()-time);
               		    console.log("LOADED STATION "+response.data.channel.shortname+" in " + dur +"ms");  
	                    that.station = response.data.channel;
						
	                    that.tz = response.data.timezone;
	                    that.tz2 = response.data.secondTimezone;
	                    
	                    that.timezones = [];//response.data.allTimezones;
	                    for ( var t in response.data.allTimezones)
	                    {
	                    	that.timezones.push( {id: response.data.allTimezones[t].id, label: response.data.allTimezones[t].name});
	                    	if ( response.data.allTimezones[t].id == that.timezoneId )
	                    	{
	                    		that.selectedTimezone = {id: response.data.allTimezones[t].id, label: response.data.allTimezones[t].name};
	                    		
	                    	}
	                    }

	                    resolve (response.data.timezone);
	                 }).catch(e => {
	                        that.$toast.error("loading data for timezones: " + e.response.data, 'Error', { position: "topRight" });
							reject ();
	                    });
	
	           });
	           timezoneLoad.then( timeZone => {
				    
	           		that.reload();
	           });
	           
	      });
    },
    
    resetFilter() {
        let that = this;
        that.selectedPrograms = new Array();
    	//that.selectedCopies = new Array();
    	that.selectedClients = new Array();
    	that.selectedBusinessTypes = new Array();
    	that.selectedFamilies = new Array();
    	that.selectedInventories = new Array();
    	//that.selectedStates = new Array();
    	//that.allPrograms = new Array();
    	//that.allCopies = new Array();
    	//that.allClients = new Array();
    	//that.allProducts = new Array();
    },
    
    
    startLoader()
    {
    	if ( !this.loadingActive)
      	{
	      	this.loadingActive = true;
	      	this.loader = this.$loading.show({
	                    // Optional parameters
	                    container: this.$refs.formContainer,
	                    canCancel: true,
	                    programmatic: false,
	                    onCancel: this.onCancel,
	                    color: '#000000',
					    loader: 'dots',
					    width: 64,
					    height: 64,
					    active: true,
					    backgroundColor: '#ffffff',
					    opacity: 0.5,
					    zIndex: 999,
	                });
	    }
    },
	stopLoader()
	{
		clearInterval( timers.pop());
		this.loadingActive = false;
		
		this.loader.hide();
	}
  },
  created() { 
    this.bgColor = sessionStorage.bgColor;
    this.showProgressBar = false;
    this.PBOptionsZERO.progress.backgroundColor = sessionStorage.bgColor;
    this.activeDate = new Date();
    this.activeDate.setDate( this.activeDate.getDate() - 7);
    this.timezoneId = sessionStorage.timezoneId;
    this.noOfDays = 21;
  	if ( this.$route.query.stationId && this.$route.query.day )
    {
    	this.activeDate = new Date(this.$route.query.day);
    	this.selectedStation=null;
    	this.stationId=parseInt(this.$route.query.stationId);
    }
	this.loadStation();
    initAppMode();
  },
  watch: {
   //selectedClients: function() { this.reload() },
   //selectedFamilies: function() { this.reload() },
   onlyPlaced: function() { this.reload() },
  },
  updated() {
  		//console.log("update...");
	  this.$nextTick(function () {
		    // Code that will run only after the
		    // entire view has been re-rendered
		    //console.log("stopping loader");
		    if (this.loadingActive)
		    {
		        timers.push( setTimeout( this.stopLoader, 100));
		    }
		  })
 	}
}
</script>

<style scoped>
ul {
  list-style: square inside none;
}
.bold {
  font-weight: bold;
}
.SVcontrolLabel {
	display: inline-block;
	font-weight: bold;
	font-size: 8pt;
	padding-top: 6pt;
}
.myButton {
	//width: 18%;
    padding: 6px 8px;
    outline: none;
    border-radius: 3px;
    height: 22pt;
    font-size: 9pt;
    background-color: #eef;
    border: 1px outset #aaa;
    color: rgb(0, 0, 0);
    margin-top: 4pt;
    margin-right: 4pt;
    margin-bottom: 4pt;
}
.SVcontrolLabel {
	font-weight: bold;
	padding-top: 9pt;
}

.bold {
  font-weight: bold;
}

.dontWrap {
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
  width: 100%;
  display: flex;
}
.smallText {
  font-size: 8pt;
  height: 12pt;
}
.BMSection {
    margin-top: 3pt;
	height:20pt; 
	width: 100%;
	border: 1pt ridge #ccc;
	vertical-align: middle; 
	text-align: center;
	display: inline-flex; 
	color: #666; 
	background-color: #eee; 
	font-weight: bold; 
	font-size: 10pt;
}
.BMSectionDetail {
    padding-top:  3pt; 
    padding-left: 20pt;
    text-align: center;
	width: 100%;
}
.floatLeft {
    float: left;
}
.spotDetail { 
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 7pt;  
  width: 100% !important; 
  height: 16pt; 
  border-left: 1pt solid #ccc; 
  border-bottom: 1pt solid #ccc;

  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
  overflow: hidden;
}
.uploadArea {
 float: left; 
 width: 82%; 
 height: calc(100vH - 120px);
 border: 1pt dotted #ccc;
}
.reqLineBG {
	border: 2pt solid red !important;
}
.header {
  font-size: 14pt;
  font-weight: bold;
}
.BMday {
  display: table-cell;
  margin: 5pt;
  overflow-x: hidden;
  border-right: 1px dotted #ccc;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
}
.BMdayH {
  display: table-cell;
  margin: 5pt;
  height: 22pt;
  overflow-x: hidden;
  border-right: 1px dotted #ccc;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
}
.noBreak {
  overflow-x: hidden;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
}
.simple {
  width: 32pt;
  font-size: 7pt;
  font-weight: normal;
}
.reqLineBG2 {
    cursor: pointer;
    color: #00a;
	background-color: #fcf !important;
	//box-shadow: 0pt 2pt 0pt 0 #888;
}
</style> 